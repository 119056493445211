import type { AlertProps } from "~/types/alert";

import { json, type LoaderFunction } from "@remix-run/node";
import { SignIn, useClerk } from "@clerk/remix";

import { getSession } from "~/sessions";
import { useLoaderData } from "@remix-run/react";

import { getAuth } from "@clerk/remix/ssr.server";
import { useEffect } from "react";
import Layout from "~/layout/Layout";
import { useAlertMessage } from "~/context/AlertMessageContext";

export default function SignInPage() {
  const { message, isSignedIn } = useLoaderData<{
    message: AlertProps | null;
    isSignedIn: boolean;
  }>();
  const { signOut } = useClerk();
  const { setAlertMessage } = useAlertMessage();

  useEffect(() => {
    async function signOutUser() {
      if (!isSignedIn) {
        await signOut();
      }
    }
    signOutUser();
  }, [isSignedIn, signOut]);
  useEffect(() => {
    if (message) {
      setAlertMessage(message);
    }
  }, [message, setAlertMessage]);

  return (
    <Layout>
      <main className="flex flex-col justify-center items-center">
        <h1 className="text-center text-small-title mt-big-gutter mb-gutter">Sign In</h1>
        <SignIn />
      </main>
    </Layout>
  );
}

export const loader: LoaderFunction = async (args) => {
  const { userId } = await getAuth(args);

  const session = await getSession(args.request.headers.get("Cookie"));
  let sessionMessage: AlertProps | null;

  try {
    sessionMessage = JSON.parse(session.get("message"));
  } catch (error) {
    sessionMessage = null;
  }

  return json({
    message: sessionMessage,
    isSignedIn: Boolean(userId) && !sessionMessage?.title.includes("error"),
  });
};
